@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #42a4ff;
  --navy-dark: #15212b;
}

@layer base {
  @font-face {
    font-display: swap;
    font-family: Product Sans;
    font-weight: 400;
    src: url(./fonts/ProductSans-Regular.ttf) format("truetype");
  }

  @font-face {
    font-display: swap;
    font-family: Product Sans;
    font-weight: 700;
    src: url(./fonts/ProductSans-Bold.ttf) format("truetype");
  }
}

@layer components {
  button,
  .btn {
    @apply rounded-md duration-700 transform inline-block;
    @apply focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-50;
  }

  .btn:not(.btn-link) {
    @apply py-2 px-3 lg:px-5 transition-colors;
  }

  .btn-outline {
    @apply border-2 !bg-transparent !transition-opacity;
  }

  .btn-link {
    @apply p-0;
  }

  .btn-link,
  .btn-outline {
    @apply transition-opacity hover:opacity-80;
  }

  .btn-primary:not(.btn-link) {
    @apply bg-primary hover:bg-opacity-80 text-white;
  }

  .btn-primary.btn-link {
    @apply text-primary;
  }

  .btn-primary.btn-outline {
    @apply border-primary text-primary;
  }

  .btn-secondary:not(.btn-link) {
    @apply bg-secondary hover:bg-opacity-80 text-white;
  }

  .btn-secondary.btn-link {
    @apply text-secondary;
  }

  .btn-secondary.btn-outline {
    @apply border-secondary text-secondary;
  }

  .btn-navy:not(.btn-link) {
    @apply bg-navy hover:bg-opacity-80 text-white;
  }

  .btn-navy.btn-link {
    @apply text-navy;
  }

  .btn-navy.btn-outline {
    @apply border-navy text-navy;
  }

  .btn-dark:not(.btn-link) {
    @apply bg-navy-dark hover:bg-opacity-80 text-white;
  }

  .btn-dark.btn-link {
    @apply text-navy-dark;
  }

  .btn-dark.btn-outline {
    @apply border-navy-dark text-navy-dark;
  }

  .btn-white:not(.btn-link) {
    @apply bg-white hover:bg-opacity-80 text-navy;
  }

  .btn-white.btn-link {
    @apply text-white;
  }

  .btn-white.btn-outline {
    @apply border-white text-white;
  }

  button.no-focus-ring:focus {
    @apply ring-transparent;
  }

  .heroSection {
    @apply flex lg:container mx-auto relative;
  }

  .heroInner {
    @apply flex flex-grow space-y-4 items-center min-w-0 py-10 sm:py-14 md:space-y-0 md:space-x-4;
  }

  .heroHeader {
    @apply flex items-center justify-center space-x-2 my-4 md:mt-0 md:flex-col;
  }

  .heroContent {
    @apply flex-grow text-center px-6 sm:px-10 md:text-left lg:px-14;
  }

  .heroDescription {
    @apply text-base;
  }

  .heroAction {
    @apply mt-3 text-sm md:text-base;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.crispImage {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

/* Safari-specific image rendering to avoid overly-pixelated rendering */
_::-webkit-full-page-media,
_:future,
:root .crispImage {
  image-rendering: auto;
}

/* Firefox image-rendering override */
@-moz-document url-prefix() {
  .crispImage {
    image-rendering: auto;
  }
}

@media screen and (prefers-reduced-motion: no-preference) {
  @layer components {
    .random-animation {
      transition: transform 1s ease-in-out;
    }

    .random-animation.expand {
      transform: scaleX(1.1) scaleY(1.1);
    }

    .random-animation.up {
      transform: translateY(-5px);
    }

    .random-animation.down {
      transform: translateY(5px);
    }

    .random-animation.highlight {
      animation: highlight 5s linear infinite;
    }
  }
}

@keyframes highlight {
  0%,
  100% {
    @apply opacity-30 text-white;
  }
  30%,
  60% {
    @apply opacity-100 text-primary;
  }
}
